import { IncIcon } from 'public/icons/IncIcon'
import { PHLPv2Icon } from 'public/icons/PHLPv2Icon'

import { CoastIcon, DaiIcon, HexIcon, PulseChainIcon, PulseXIcon, UsdcIcon, UsdtIcon, WaitV2Icon } from '~public'

export const cryptoCurrencies = [
  {
    code: 'PLS',
    flag: <PulseChainIcon style={{ height: '40px', width: '40px' }} />,
  },
  {
    code: 'PLSX',
    flag: <PulseXIcon style={{ height: '30px', width: '30px', marginLeft: '5px', marginRight: '5px' }} />,
  },
  {
    code: 'HEX',
    flag: <HexIcon style={{ height: '30px', width: '30px', marginLeft: '5px', marginRight: '5px' }} />,
  },
  {
    code: 'WAIT',
    flag: <WaitV2Icon style={{ height: '30px', width: '30px', marginLeft: '5px', marginRight: '5px' }} />,
  },
  {
    code: 'INC',
    flag: <IncIcon style={{ height: '30px', width: '30px', marginLeft: '5px', marginRight: '5px' }} />,
  },
  {
    code: 'PHLPv2',
    flag: <PHLPv2Icon style={{ height: '30px', width: '30px', marginLeft: '5px', marginRight: '5px' }} />,
  },
  {
    code: 'USDC',
    flag: <UsdcIcon style={{ height: '30px', width: '30px', marginLeft: '5px', marginRight: '5px' }} />,
  },
  {
    code: 'USDT',
    flag: <UsdtIcon style={{ height: '30px', width: '30px', marginLeft: '5px', marginRight: '5px' }} />,
  },
  {
    code: 'DAI',
    flag: <DaiIcon style={{ height: '30px', width: '30px', marginLeft: '5px', marginRight: '5px' }} />,
  },
  {
    code: 'CST',
    flag: <CoastIcon style={{ height: '30px', width: '30px', marginLeft: '5px', marginRight: '5px' }} />,
  },
]
