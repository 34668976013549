export const EMAIL_TEMPLATES = {
  //Code is close to being there, but there are verification errors
  EMAIL_ADDRESS_VERIFICATION: 'd-33ff13f100f54de29ab678203b78bd8a',
  COAST_ACCOUNT_OPENED: 'd-041ec1ed4e4748b7acc92b5d0c27cc66',
  //Not tested but no reason it shouldn't work. Just don't wanna close my account at the moment
  COAST_ACCOUNT_CLOSED: 'd-24cdc41449ba40f2bd65a2cb9a987c86',
  COAST_ACCOUNT_LOCKED: 'd-7eca1645468144b9b1b4d2a3a0a46782',
  REGISTRATION_ERROR: 'd-33af19595ae2451c97084eded833be35',
  //Insufficient permissions when trying to update the user
  RESET_PASSWORD: 'd-ff8557fc6ec6457780745efc58648f86',
  //Waiting on permissions, but correct email is in the correct places
  PASSWORD_CONFIRMED: 'd-98d95209a4f8412dae6a2c1f8dae6c5e',
  MAGIC_LINK_SIGNUP: 'd-3fe77977fee0400c96bd2128fee85fe7',
  MAGIC_LINK_SIGNIN: 'd-f1ae7be5d5284c20a3284dab658a5a18',
  WALLET_ADDED: 'd-c7e8dc2e367940898a078f8136a0637a',
  WALLET_REMOVED: 'd-e68e625abf5040af9071d562f40963ba',
  //Done,but need to change template or figure out how to get account name and number for email
  BANK_ACCOUNT_ADDED: 'd-eb8a4ee645e74e51a45ea248d99f2113',
  //This function isn't working in the app, so email is on hold
  BANK_ACCOUNT_REMOVED: 'd-dc81fe3d2be74e71adaeabd9878e5aaa',
  //Need to test this by removing card through postman and re-adding
  CARD_ADDED: 'd-e864f7ee8f1641fcaa3cf86dd5e2722e',
  CARD_REMOVED: 'd-119c4edc490a43eab44bcb8813a399ca',
  //Not tested
  PROFILE_UPDATED: 'd-8c4297ea635b494ea96daa7fb3976612',
  USD_DEPOSIT: 'd-641c1018017f43949e9b3f8029740f6c',
  USD_WITHDRAW: 'd-49cf79470cbb427499064450916e5a10',
  //Think this will need to be a backend one
  USDC_DEPOSIT: 'd-41dedbfbe1624241870f1c11e1c26caa',
  USDC_WITHDRAW: 'd-c60cf78ebe8942b68e02e448d8802781',
  SWAP_USD_USDC: 'd-84b838b64fd64a0697f2e336e746b7a8',
  //Check that template was fixed
  SWAP_USDC_USD: 'd-9fc6c2e03e1a43b194d2c1e67aba9d3f',
  ACH_FRAUD_ERROR: 'd-e57149cf50764ff8ab11f64fa8120982',

  ACH_FUNDS_CONFIRMED: 'd-e8cb68fdcf4f4b178722ead34f412534',
  ACCOUNT_REGISTRATION_REMINDER: 'd-7f4c0b1545584f24a879969df92e4181',

  BUY_PLS: 'd-4257777c875e4113aa822867a2e2ef6a',
  BUY_PLSX: 'd-09c62b43b2a9424498265ce67d90711c',
  BUY_HEX: 'd-4fda27eb48ab47d2b5d5da5e4cbc007b',
  BUY_WAIT: 'd-cf90bde78cc74499b0006d9c66179644',
  BUY_INC: 'd-1752e62446d648e7bf6374129b65b18e',
  BUY_PHLPv2: 'd-8b0af15c71b247229253d9ce70bcb6bf',
} as const

export const CONFIG = {
  // can be a string with an email address, or an object with name/email
  FROM: {
    name: 'Coast',
    email: 'support@0xcoast.com',
  },
}
